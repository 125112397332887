import React, { useState } from 'react';
import reactLogo from '../Images/pikchar.png';
// import '../App.css'

const AccDeletion = () => {
  const [email, setEmail] = useState('');

  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setLoaded(true);
    var apiUrl =
      'https://customizebychoice.com/pikchar_api/admin/deleteAccount';
    var requestBody = {
      mail: email,
      title: 'Pikchar Account Data Delete',
      article: 'This is a confirmation mail to delete your pikchar account!',
      imageUrl: 'none',
    };
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      if (response.ok) {
        
      } else {
        console.error('Failed to submit email.');
      }
    } catch (error) {
      console.error('Error submitting email:', error);
    } finally {
      // Set loading back to false to hide the loading screen
      setLoading(false);
    }
  };

  return (
    <div className='flex flex-col items-center justify-center min-h-screen bg-gray-100'>
      <div className='max-w-sm w-full p-8 bg-cherryRed rounded-lg shadow-lg'>
        <div className='mb-8 text-center'>
          <a
            href='https://play.google.com/store/apps/details?id=com.gyanesh.mobiletalkies&hl=en_SG'
            target='_blank'
            rel='noreferrer'>
            <img
              src={reactLogo}
              className='w-24 h-24 mx-auto mb-4'
              alt='React logo'
            />
          </a>
          <h1 className='text-2xl  text-gray-700 font-bold'>
            Pikchar Account Deletion
          </h1>
        </div>
        <form onSubmit={handleSubmit}>
          <input
            className='w-full px-4 py-3 mb-4 leading-tight text-gray-700 bg-gray-200 border border-gray-300 rounded appearance-none focus:outline-none focus:bg-bgBlue focus:border-blue-500'
            type='email'
            placeholder='Enter your email'
            value={email}
            onChange={handleEmailChange}
          />
          <button
            className='w-full py-3 text-white bg-blue-500 rounded hover:bg-blue-700 focus:outline-none'
            type='submit'>
            {loading ? 'Submitting...' : 'Submit'}
          </button>
        </form>
        {!loading && (
          <p className='mt-4  text-gray-700 text-sm text-center'>
            After submitting your email, you will receive an account deletion
            email.
          </p>
        )}
        {loaded && (
          <p className='mt-4 text-sm  text-center text-green-500'>
            Account deletion mail sent!
          </p>
        )}
      </div>
      <p className='mt-4 text-sm'>
        <a
          className='text-blue-500 hover:underline'
          href='https://play.google.com/store/apps/details?id=com.gyanesh.mobiletalkies&hl=en_SG'
          target='_blank'
          rel='noopener noreferrer'>
          Download Pikchar from Play Store
        </a>
      </p>
    </div>
  );
};

export default AccDeletion;
