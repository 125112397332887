import axios from '../api/axios';
import useAuth from './useAuth';

const useRefreshToken = () => {
    const { setAuth } = useAuth();

    const refresh = async () => {
        let refreshToken = localStorage.getItem('refreshToken');
        if (!refreshToken) {
            refreshToken = "not_found";
        }
        const response = await axios.post(
            '/refresh',
            JSON.stringify({ refreshToken }),
            {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true
            }
        );

        setAuth(prev => {
            return { ...prev, accessToken: response.data.accessToken };
        });

        return response.data.accessToken;
    };
    
    return refresh;
};

export default useRefreshToken;
