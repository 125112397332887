import React from 'react';
import Hero from './Hero';
import { Swiper, SwiperSlide } from 'swiper/react';
import { A11y, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import bannerData from '../MoviesList/banner.json';

const mapBannerData = (data) => {
  const banners = [];
  for (let i = 1; i <= data.MainImage.length; i++) {
    if (data.MainImage[`title${i}`]) {
      banners.push({
        id: data.MainImage[`mainImage_code${i}`],
        image: data.MainImage[i.toString()],
        title: data.MainImage[`title${i}`],
        genre: data.MainImage[`genre${i}`],
        rating: data.MainImage[`moreinfo${i}`],
      });
    }
  }
  return banners;
};

const mapPhoneBannerData = (data) => {
  const banners = [];
  for (let i = 1; i <= data.MainImage.length; i++) {
    if (data.MainImage[`title${i}`]) {
      banners.push({
        id: data.MainImage[`mainImage_code${i}`],
        image: data.MainImage['phone'+i.toString()],
        title: data.MainImage[`title${i}`],
        genre: data.MainImage[`genre${i}`],
        rating: data.MainImage[`moreinfo${i}`],
      });
    }
  }
  return banners;
};

const HeroBanner = () => {
  const banners = mapBannerData(bannerData);
  const phonebanners = mapPhoneBannerData(bannerData);

  return (
    <>
      {/* Display on large screens */}
      <div className="hidden md:block">
        <Swiper
          modules={[A11y, Autoplay]}
          slidesPerView={1}
          loop={true}
          autoplay={{
            delay: 5000,
          }}
        >
          {banners.map((banner) => (
            <SwiperSlide key={banner.id}>
              <Hero {...banner} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      {/* Display on mobile screens */}
      <div className="block md:hidden">
        <Swiper
          modules={[A11y, Autoplay]}
          slidesPerView={1}
          loop={true}
          autoplay={{
            delay: 5000,
          }}
        >
          {phonebanners.map((banner) => (
            <SwiperSlide key={banner.id}>
              <Hero {...banner} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
};

export default HeroBanner;
