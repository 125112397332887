import React, { useState, useEffect, useRef, useCallback } from 'react';
import { GoogleLogin } from '@react-oauth/google';
import axios from '../api/axios';
import useAuth from '../hooks/useAuth';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import loginImg from '../Images/my_space_login_in.webp';
// import { FcGoogle } from "react-icons/fc";
import Loader from '../Common/Loader';
import { useNavigate, useLocation } from 'react-router-dom';
import Modal from '../Common/Modal';

import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics';

const firebaseConfig = {
  apiKey: 'AIzaSyAa6xW0YnMj5e5vqweOQ90wXf3IXwe36Zc',
  authDomain: 'pikchar-web.firebaseapp.com',
  projectId: 'pikchar-web',
  storageBucket: 'pikchar-web.appspot.com',
  messagingSenderId: '254455429114',
  appId: '1:254455429114:web:4e5f7f04390ff65395d643',
  measurementId: 'G-N0VBP059WJ',
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const MySpace = () => {
  const [userSignedIn, setUserSignedIn] = useState(false);
  const [userData, setUserData] = useState(null);
  const axiosPrivate = useAxiosPrivate();
  const { setAuth } = useAuth();
  const initialMount = useRef(true);
  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [responseCode, setResponseCode] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || '/';

  const getUser = useCallback(async () => {
    try {
      setLoading(true);
      const response = await axiosPrivate.get('/getuser', {
        headers: { 'Content-Type': 'application/json' },
      });
      if (response.status === 200) {
        const data = response.data;
        setUserData(data);
        setUserSignedIn(true);
      } else {
        setUserSignedIn(false);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      if (err.name !== 'AbortError') {
        setUserSignedIn(false);
      }
    }
  }, [axiosPrivate]);

  useEffect(() => {
    if (initialMount.current) {
      initialMount.current = false;
      getUser();
    }
  }, [getUser]);

  const onSuccess = async (response) => {
    const gToken = response.credential;
    setLoading(true);
    try {
      const ipResponse = await fetch('https://api.ipify.org?format=json');
      const ipData = await ipResponse.json();
      const ip = ipData.ip;

      const response = await axios.post(
        '/signup',
        JSON.stringify({ ip: ip, at: gToken }),
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        }
      );
      setLoading(false);
      if (response.status === 200) {
        const accessToken = response?.data?.accessToken;
        const refreshToken = response?.data?.refreshToken;
        const email = response?.data?.email;
        const uid = response?.data?.id;
        localStorage.setItem('refreshToken', refreshToken);
        setAuth({ email, uid, accessToken });
        setUserSignedIn(true);
        navigate(from, { replace: true });
        logEvent(analytics, 'user_login');
      } else {
        setResponseCode(response.status);
        setModalOpen(true);
      }
    } catch (error) {
      setLoading(false);
      setResponseCode(error.response?.status || 'Unknown Error');
      setModalOpen(true);
    }
  };

  const onFailure = (error) => {
    setResponseCode(error.response?.status || 'Unknown Error');
    setModalOpen(true);
  };

  const handleLogout = async () => {
    try {
      setLoading(true);
      const response = await axiosPrivate.get('/logout', {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      });
      setLoading(false);
      if (response.status === 200) {
        setAuth({});
        setUserSignedIn(false);
        setUserData(null);
      } else {
        setResponseCode(response.status);
        setModalOpen(true);
      }
    } catch (error) {
      setLoading(false);
      setResponseCode(error.response?.status || 'Unknown Error');
      setModalOpen(true);
    }
  };

  return (
    <div>
      {loading ? (
        <div className='h-screen flex justify-center items-center'>
          <div className=' w-48'>
            <Loader />
          </div>
        </div>
      ) : (
        <div className=''>
          <div className=''>
            {userSignedIn ? (
              <div className='flex flex-col items-center justify-center h-screen text-xl ml-4 sm:ml-10 lg:ml-28'>
                <div className='flex flex-col sm:flex-row justify-center items-center gap-4 sm:gap-10 p-5 mb-8'>
                  <div className='flex flex-col justify-center items-center gap-4'>
                    <p>
                      <img
                        src={userData?.profile_url}
                        className='w-16 h-16 sm:w-24 sm:h-24 rounded-full'
                        alt={userData?.name}
                      />
                    </p>
                    {/* <p className='uppercase font-semibold'>{user?.name}</p> */}
                  </div>
                  <div className='font-semibold sm:mr-10 sm:text-lg  text-sm'>
                    <p className='mb-2'>
                      Name: <span className='font-bold'>{userData?.name}</span>
                    </p>
                    <p className='mb-4'>
                      Email:{' '}
                      <span className='font-bold'>{userData?.email}</span>
                    </p>
                  </div>
                </div>

                <table className='divide-y lg:text-lg text-sm'>
                  <tbody>
                    <tr>
                      <td className='px-4 sm:px-6 py-2 whitespace-nowrap '>
                        Registration Date:
                      </td>
                      <td className='px-1 sm:px-24 py-2 whitespace-nowrap'>
                        <span className='font-semibold'>
                          {userData?.registration_date}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td className='px-4 sm:px-6 py-2 whitespace-nowrap'>
                        Premium Status:
                      </td>
                      <td className='px-1 sm:px-24 py-2 whitespace-nowrap'>
                        <span className='font-semibold'>
                          {userData?.prem_status === null ||
                          userData?.prem_status === 'unactive'
                            ? ' Not Active'
                            : userData?.prem_status}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td className='px-4 sm:px-6 py-2 whitespace-nowrap'>
                        Active Windows Device:
                      </td>
                      <td className='px-1 sm:px-24 py-2 whitespace-nowrap'>
                        <span className='font-semibold'>
                          {userData?.ip_address2}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td className='px-4 sm:px-6 py-2 whitespace-nowrap'>
                        Active Android Device:
                      </td>
                      <td className='px-1 sm:px-24 py-2 whitespace-nowrap'>
                        <span className='font-semibold'>
                          {' '}
                          {userData?.ip_address1 === null ||
                          userData?.ip_address1 === 'null'
                            ? 'No devices'
                            : userData?.ip_address1}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td className='px-4 sm:px-6 py-2 whitespace-nowrap '>
                        Last Login:
                      </td>
                      <td className='px-1 sm:px-24 py-2 whitespace-nowrap'>
                        <span className='font-semibold'>
                          {userData?.weblogin_date}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td
                        className='px-4 sm:px-6 py-10 whitespace-nowrap'
                        colSpan='2'>
                        <button
                          onClick={handleLogout}
                          className='page-button w-full h-12'>
                          Logout
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ) : (
              <div className=' flex flex-col justify-center items-center h-screen'>
                <div className=' mb-8'>
                  <img src={loginImg} alt='' className=' w-full' />
                </div>
                <div className=' flex flex-col justify-center items-center'>
                  <p className=' font-bold sm:text-4xl text-3xl mb-6 text-center'>
                    Login to Pikchar
                  </p>
                  <p className=' sm:text-lg text-sm font-semibold mb-4'>
                    log in with the same email id you use in Pikchar app! 
                  </p>
                  <GoogleLogin onSuccess={onSuccess} onError={onFailure} />
                  {/* <button className=' flex flex-row justify-center items-center gap-5 pr-5  text-lg bg-white text-black sm:w-44 w-36 h-10 rounded-lg font-semibold hover:scale-105 duration-200 ease-linear transform'> <FcGoogle className=' text-2xl' /> Login</button> */}
                </div>
              </div>
            )}
          </div>
          {modalOpen && (
            <Modal
              statusCode={responseCode}
              onClose={() => setModalOpen(false)}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default MySpace;
