import React, { useState } from 'react';

function AdultPromo() {
  const [loading, setLoading] = useState(false);
  const [downloadStarted, setDownloadStarted] = useState(false);

  const handleDownload = () => {
    setLoading(true);
    setDownloadStarted(false);

    // Create an anchor to initiate download programmatically
    const link = document.createElement('a');
    link.href = 'https://customizebychoice.com/pikchar_api/web/getfile';
    link.setAttribute('download', '');
    
    // Handle download events
    link.addEventListener('click', () => {
      setDownloadStarted(true); // Notify the user download is starting
    });

    // Append link to the body, initiate click, then remove it
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // Hide loading after a short delay
    setTimeout(() => {
      setLoading(false);
    }, 2000); // Adjust delay as needed
  };

  return (
    <div
      className="relative bg-cover bg-center bg-no-repeat min-h-screen flex flex-col items-center justify-center lg:justify-between"
      style={{
        backgroundImage: "url('https://i.imgur.com/RHaRKbP.png')",
      }}
    >
      {/* Overlay for darkening the background */}
      <div className="absolute inset-0 bg-black bg-opacity-60"></div>

      {/* Loading Screen */}
      {loading && (
        <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-75 z-20">
          <div className="text-white text-xl font-semibold">Preparing your download...</div>
        </div>
      )}

      {/* Left Content */}
      <div className="relative z-10 text-center max-w-lg mx-auto p-4">
        {/* Header */}
        <h1 className="text-3xl sm:text-4xl font-bold text-white mb-4 leading-tight">
          Hot Indian desi videos & movies for FREE!
        </h1>
        <p className="text-white text-base mb-6">
          Watch Indian desi sexy movies & webseries for free on RaatRang App! No Ads! No Virus!
        </p>

        {/* Download Button */}
        <button
          onClick={handleDownload}
          className="inline-block bg-red-600 hover:bg-red-700 text-white font-bold py-3 px-8 rounded-full text-xl shadow-xl transform transition hover:scale-105 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-600"
        >
          Download Now
        </button>

        {/* Download Notification */}
        {downloadStarted && (
          <p className="mt-4 text-green-500 font-semibold">RaatRang.APK is being downloaded...</p>
        )}
      </div>

      {/* Screenshots (visible on mobile) */}
      <div className="relative z-10 flex flex-col sm:flex-row sm:space-x-4 items-center mt-8 lg:mt-0">
        {/* Portrait Mobile Screenshot */}
        <img
          src="https://i.imgur.com/Kfpps88.png"
          alt="App Portrait Mobile Screenshot"
          className="w-64 sm:w-72 h-auto rounded-xl shadow-2xl mb-4 sm:mb-0"
        />

        {/* Landscape Mobile Screenshot */}
        <img
          src="https://i.imgur.com/qmKrVFs.png"
          alt="App Landscape Mobile Screenshot"
          className="w-80 sm:w-96 h-auto rounded-xl shadow-2xl"
        />
      </div>
    </div>
  );
}

export default AdultPromo;