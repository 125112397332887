import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { mainData } from '../MainBannerPage/MainBanner';
import { Link } from 'react-router-dom';
import { FaPlay } from 'react-icons/fa';
import { Squash as Hamburger } from 'hamburger-react';
import { TiTick } from "react-icons/ti";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { FaAngleDown } from "react-icons/fa";

const Category = () => {
  const [data, setData] = useState([]);
  const [selectedGenres, setSelectedGenres] = useState(new Set());
  const [selectedLanguages, setSelectedLanguages] = useState(new Set());
  const [selectedYears, setSelectedYears] = useState(new Set());
  const [selectedCategories, setSelectedCategories] = useState(new Set());
  const [filterType, setFilterType] = useState('movie');
  const [genreSelected, setGenreSelected] = useState(false);
  const [languageSelected, setLanguageSelected] = useState(false);
  const [categorySelected, setCategorySelected] = useState(false);
  const [itemsToShow, setItemsToShow] = useState(30);
  const [loading, setLoading] = useState(false);
  const [isNavVisible, setIsNavVisible] = useState(true);
  const [categDropdownOpen, setCategDropdownOpen] = useState(false);
  const [genreDropdownOpen, setGenreDropdownOpen] = useState(false);
  const [languageDropdownOpen, setLanguageDropdownOpen] = useState(false);
  const [yearDropdownOpen, setYearDropdownOpen] = useState(false);
  
  const CategDropdown = () => {
    setCategDropdownOpen(!categDropdownOpen);
  };

  const GenreDropdown = () => {
    setGenreDropdownOpen(!genreDropdownOpen);
  };

  const LanguageDropdown = () => {
    setLanguageDropdownOpen(!languageDropdownOpen);
  };

  const YearDropdown = () => {
    setYearDropdownOpen(!yearDropdownOpen);
  };

  const toggleNav = () => {
    setIsNavVisible(!isNavVisible);
  };

  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await mainData();
        setData(result);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleTypeFilterChange = (type) => {
    setFilterType(type === filterType ? null : type);
    setSelectedCategory(type);
  };

  const filteredData = useMemo(() => {
    if (!genreSelected && !languageSelected && !categorySelected) {
      return [];
    }
    return data.filter(
      (item) =>
        (!genreSelected ||
          Array.from(selectedGenres).some((genre) =>
            item.genres.includes(genre)
          )) &&
        (!categorySelected ||
          Array.from(selectedCategories).some(
            (category) => item.category === category
          )) &&
        (!languageSelected ||
          Array.from(selectedLanguages).some((language) =>
            item.language.includes(language)
          )) &&
        (!selectedYears.size ||
          selectedYears.has(item.name.match(/\((\d{4})\)$/)?.[1])) &&
        (!filterType ||
          (filterType === 'movie'
            ? /\(\d{4}\)$/.test(item.name)
            : !/\(\d{4}\)$/.test(item.name)))
    );
  }, [
    data,
    genreSelected,
    languageSelected,
    selectedGenres,
    selectedLanguages,
    selectedCategories,
    categorySelected,
    filterType,
    selectedYears,
  ]);

  const loadMoreItems = useCallback(() => {
    setItemsToShow((prevItemsToShow) =>
      Math.min(prevItemsToShow + 30, filteredData.length)
    );
  }, [filteredData]);

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop + 1 >=
        document.documentElement.scrollHeight
      ) {
        loadMoreItems();
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [loadMoreItems]);

  const handleCheckboxChange = (type, value) => {
    const newSet = new Set(
      type === 'genre'
        ? selectedGenres
        : type === 'language'
        ? selectedLanguages
        : type === 'category'
        ? selectedCategories
        : selectedYears
    );
    if (newSet.has(value)) {
      newSet.delete(value);
    } else {
      newSet.add(value);
    }
    if (type === 'genre') {
      const newSelectedGenres = new Set(selectedGenres);
      if (newSelectedGenres.has(value)) {
        newSelectedGenres.delete(value);
      } else {
        newSelectedGenres.add(value);
      }
      setSelectedGenres(newSelectedGenres);
      setGenreSelected(newSelectedGenres.size > 0);
    } else if (type === 'language') {
      const newSelectedLanguages = new Set(selectedLanguages);
      if (newSelectedLanguages.has(value)) {
        newSelectedLanguages.delete(value);
      } else {
        newSelectedLanguages.add(value);
      }
      setSelectedLanguages(newSelectedLanguages);
      setLanguageSelected(newSelectedLanguages.size > 0);
    } else if (type === 'category') {
      const newSelectedCategories = new Set(selectedCategories);
      if (newSelectedCategories.has(value)) {
        newSelectedCategories.delete(value);
      } else {
        newSelectedCategories.add(value);
      }
      setSelectedCategories(newSelectedCategories);
      setCategorySelected(newSelectedCategories.size > 0);
    } else {
      setSelectedYears(newSet);
    }
    setItemsToShow(30); // Reset items to show when genres or languages change
  };

  const processItems = (items) => {
    const itemsWithOther = items.filter(item => item.trim() === '').map(() => 'Other');
    const filteredItems = items.filter(item => item.trim() !== '');
    return [...filteredItems, ...itemsWithOther];
  };

  const uniqueGenres = useMemo(() => {
    return processItems(Array.from(new Set(data.flatMap((item) => item.genres.split(' • ')))));
  }, [data]);

  const uniqueLanguages = useMemo(() => {
    return processItems(Array.from(new Set(data.flatMap((item) => item.language.split(' • ')))));
  }, [data]);

  const uniqueCategories = useMemo(() => {
    return processItems(Array.from(new Set(data.flatMap((item) => item.category.split(' • ')))));
  }, [data]);

  const currentItems = filteredData.slice(0, itemsToShow);
  

  // Extract unique years from movie names
  const movieYears = Array.from(
    new Set(
      data.flatMap((item) => {
        const match = item.name.match(/\((\d{4})\)$/);
        return match ? [match[1]] : [];
      })
    )
  ).sort((a, b) => b - a);

  const [selectedCategory, setSelectedCategory] = useState('movie');
 
  

  useEffect(() => {
    if (uniqueCategories.length > 0 && selectedCategories.size === 0) {
      setSelectedCategories(new Set([uniqueCategories[0]]));
      setCategorySelected(true);
    }
  }, [uniqueCategories, selectedCategories]);

  




  return (
    <div className=' lg:mt-10 mt-5'>
     <div className=' md:ml-28 ml-2 mb-8'>
        <ul className='flex flex-row font-semibold sm:justify-start justify-center px-2 '>
          <li
            className={`cursor-pointer flex justify-center items-center gap-1 md:w-[140px] md:h-12 w-[120px] h-10 md:text-lg text-base rounded-full font-semibold transition-transform duration-500 ease-in-out   ${
              selectedCategory === 'movie' ? 'bg-white text-white bg-opacity-15  border-2 border-white' : ''
            }`}
            onClick={() => handleTypeFilterChange('movie')}
          >
            {selectedCategory === 'movie' && (
            < TiTick className=' text-3xl'/>
             )}
            Movies
          </li>
          <li
            className={`cursor-pointer flex justify-center items-center gap-1 md:w-[170px] md:h-12 w-[140px] h-10 md:text-lg text-base rounded-full font-semibold transition-transform duration-500 ease-in-out   ${
              selectedCategory === 'series' ? 'bg-white text-white bg-opacity-15  border-2 border-white' : ''
            }`}
            onClick={() => handleTypeFilterChange('series')}
          >
             {selectedCategory === 'series' && (
            < TiTick className=' text-3xl'/>
             )}
            Web-Series
          </li>
        </ul>
      
    </div>

    <div className=' md:ml-28 md:mr-10 ml-4 mr-4 grid lg:grid-cols-6 md:grid-cols-4 sm:grid-cols-3 grid-cols-2 md:gap-8 gap-5 max-md:hidden'>
        {uniqueCategories.map((category, index) => (
          <label
            key={index}
            className={`cat-label border-2 flex items-center justify-between lg:w-48 md:w-40 sm:w-36 w-32 sm:text-[16px] font-semibold text-xs  h-12 lg::pr-14 md:pr-8 pr-3 md:pl-5 pl-2 rounded-xl ${
              selectedCategories.has(category) ? 'border-[#ea1111] text-[#ea1111] bg-[#ea1111] bg-opacity-15' : 'border-[#fff]'
            }`}
          >
            <input
              type='checkbox'
              className='  cat '
              checked={selectedCategories.has(category)}
              onChange={() => handleCheckboxChange('category', category)}
            />
            {category}
          </label>
        ))}
      </div>

      <div className='relative max-md:m-2 '>
      <div className=" sm:mr-10 bg-black bg-opacity-80 border-2  border-white absolute  max-sm:left-0 md:right-2  flex md:w-[320px] w-full  h-12 justify-between md:pr-10 max-sm:pr-4 pr-0  pl-4 items-center z-20 rounded-full lg:-mt-32 md:-mt-52 sm:-mt-48 -mt-5 ">
        
        <Hamburger
          toggled={isNavVisible}
          toggle={toggleNav}
          duration={0.5}
          size={20}
          color="#fff"
          aria-label="Open Menu"
        />
        <p className=" md:text-2xl text-base font-bold"> Category Filter </p>
      </div>
      {isNavVisible && (
        
        <div className=' absolute  sm:-top-12 top-0 mt-10 sm:mt-0 bg-black bg-opacity-80 sm:right-5 right-4  w-[320px]  flex items-start px-8  flex-col  z-30 rounded-md'>
          <p className=' font-semibold cursor-pointer flex justify-center items-center gap-2 text-lg mb-2 md:hidden' onClick={CategDropdown} > 
            <FaAngleDown className=' text-xl' /> Select Category</p>
            {categDropdownOpen && (
           <div className=' mt-2 grid grid-cols-2 h-full gap-4 mb-5 overflow-x-auto'>
        {uniqueCategories.map((category, index) => (
          <label
            key={index}
            className='cyberpunk-checkbox-label'>
            <input
              type='checkbox'
              className=' cyberpunk-checkbox'
              checked={selectedCategories.has(category)}
              onChange={() => handleCheckboxChange('category', category)}
            />
            {category}
          </label>
        ))}
      </div>
      )}
           <p className="font-semibold cursor-pointer flex justify-center items-center gap-2 text-lg mb-2" onClick={GenreDropdown}>
               <FaAngleDown className=' text-xl' />Select Genre
           </p>
           {genreDropdownOpen && (
          <div className='mt-2 grid grid-cols-2 h-full gap-4 mb-5 overflow-x-auto'>
            {uniqueGenres.map((genre, index) => (
              <label
                key={index}
                className='cyberpunk-checkbox-label '>
                <input
                  type='checkbox'
                  className='cyberpunk-checkbox '
                  checked={selectedGenres.has(genre)}
                  onChange={() => handleCheckboxChange('genre', genre)}
                />
                {genre}
              </label>
            ))}
          </div>
           )}
          <p className="font-semibold cursor-pointer flex justify-center items-center gap-2 text-lg mb-2" onClick={LanguageDropdown} > <FaAngleDown className=' text-xl' />Select Language</p>
          {languageDropdownOpen && (
          <div className='mt-2 grid grid-cols-2 h-full gap-4 mb-5 overflow-x-auto'>
            {uniqueLanguages.map((language, index) => (
              <label
                key={index}
                className='cyberpunk-checkbox-label'>
                <input
                  type='checkbox'
                  className='cyberpunk-checkbox'
                  checked={selectedLanguages.has(language)}
                  onChange={() => handleCheckboxChange('language', language)}
                />
                {language}
              </label>
            ))}
          </div>
          )}
          {filterType === 'movie' && (
            <div className=' flex items-start flex-col mb-4'>
              <p className=' font-semibold cursor-pointer flex justify-center items-center gap-2 text-lg mb-2' onClick={YearDropdown}> <FaAngleDown className=' text-xl' />Select Year</p>
              {yearDropdownOpen && (
              <div className='mt-2 grid grid-cols-3 h-[500px] gap-4  overflow-x-auto'>
                {movieYears.map((year, index) => (
                  <label
                    key={index}
                    className='cyberpunk-checkbox-label'>
                    <input
                      type='checkbox'
                      className='cyberpunk-checkbox'
                      checked={selectedYears.has(year)}
                      onChange={() => handleCheckboxChange('year', year)}
                    />
                    {year}
                  </label>
                ))}
              </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
      <div className=' md:ml-28 md:mt-10 mt-20 max-md:mr-3 max-md:ml-3 flex justify-center items-center '>
      {loading ? (
        <div className='grid lg:grid-cols-6 md:grid-cols-3 grid-cols-2 max-[310px]:grid-cols-1 lg:gap-14 gap-5  '>
          {[...Array(30)].map((_, index) => (
            <div
              key={index}
              className='md:w-[180px] md:h-[230px] sm:w-[150px] sm:h-[200px] w-[105px] h-[150px]'>
              <Skeleton width='100%' height='100%' baseColor='gray' />
            </div>
          ))}
        </div>
      ) : (
        <div>
          <div className=' md:flex md:flex-wrap md:justify-start md:items-center gap-5  grid sm:grid-cols-4 grid-cols-3  max-[320px]:grid-cols-2'>
            {currentItems.map((item) => (
              <div key={item.id}>
                  <div className='relative md:w-[180px] md:h-[230px] sm:w-[150px] sm:h-[200px] w-[105px] h-[150px] overflow-hidden transition-transform ease-out duration-500 sm:hover:scale-125 hover:z-10 rounded-md'>
                  {/* <img src={item.poster} alt={item.name} className='w-full transition-transform duration-500 transform hover:scale-105' /> */}
                  <Link to={`/main-list/${item.id}`} className=' '>
                      <img src={item.poster} alt={item.name} className='md:w-[180px] md:h-[230px] sm:w-[150px] sm:h-[200px] w-[105px] h-[150px] rounded-lg transition-transform duration-500 transform sm:hover:scale-[1.20]' />
                      </Link>
                      
                  <footer className='absolute max-sm:hidden inset-0 bg-black bg-opacity-60 opacity-0 hover:opacity-100 flex flex-col justify-end text-white p-4 transition-opacity duration-300'>
                  {/* <span className=' flex-1 text-green-400 font-bold text-[10px]'>{item.languages}</span> */}
                    <Link to={`/main-list/${item.id}`} className='mb-2 bg-white text-black text-xs w-full h-8 font-semibold rounded-md duration-200 ease-linear transform hover:scale-[1.020] flex items-center justify-center gap-3'>
                      <FaPlay className='text-[10px]' /> Watch Now
                    </Link>
                    <p className="text-xs font-semibold flex-col flex flex-2">{item.name}</p>
                    <div className='text-[10px] font-semibold'>
                      <span className=' text-[#fffcc2]'>{item.genres}</span>
                      <span>{item.langauge}</span>
                      {/* <span>{item.category}</span> */}
                    </div>
                  </footer>
                </div>
                {/* <p className=' md:w-[220px] sm:w-[200px] w-[150px] flex justify-center items-center font-semibold text-sm mt-2 uppercase'>{item.name}</p> */}
              </div>
              
            ))}
          </div>
        </div>
      )}
    </div>
    
    </div>
  );
};

export default Category;
