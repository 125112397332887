import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaHome, FaSearch, FaThList, FaUserCircle } from 'react-icons/fa';
import { LuLayoutPanelLeft } from "react-icons/lu";
import Logo from '../Images/pikchar.png';

const Navbar = () => {
  const [isHovered, setIsHovered] = useState(false);
  const [isHovering, setIsHovering] = useState(false); // to manage hovering state
  const { pathname } = useLocation();

  useEffect(() => {
    let timeout;
    if (!isHovering) {
      timeout = setTimeout(() => setIsHovered(false), 400); // delay of 0.5 seconds
    } else {
      setIsHovered(true);
    }
    return () => clearTimeout(timeout);
  }, [isHovering]);

  return (
    <>
      <div className="hidden md:flex fixed top-0 h-screen flex-col duration-300 z-20">
        {isHovered && (
          <div className="fixed top-0 left-0 h-screen w-full bg-gradient-to-r from-black z-10 transition-all duration-500"></div>
        )}
        <div className="flex flex-col px-1 z-20 mt-5 ms-4 ">
          <a href="/">
            <img src={Logo} alt="Logo" className="w-10 h-10" />
          </a>
        </div>
        <div className="flex-1 flex justify-center flex-col items-start lg:gap-8 md:gap-10 px-5 z-20"
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
        >
          <NavItem icon={<FaUserCircle />} text="My Space" link="/myspace" showText={isHovered} isActive={pathname === '/myspace'} setIsHovering={setIsHovering} />
          <NavItem icon={<FaSearch />} text="Search" link="/search" showText={isHovered} isActive={pathname === '/search'} setIsHovering={setIsHovering} />
          <NavItem icon={<FaHome />} text="Home" link="/" showText={isHovered} isActive={pathname === '/'} setIsHovering={setIsHovering} />
          <NavItem icon={<LuLayoutPanelLeft />} text="Updates" link="/updates" showText={isHovered} isActive={pathname === '/updates'} setIsHovering={setIsHovering} />
          <NavItem icon={<FaThList />} text="Category" link="/category" showText={isHovered} isActive={pathname === '/category'} setIsHovering={setIsHovering} />
        </div>
      </div>

      <div className="md:hidden fixed bottom-0 left-0 w-full z-20 flex justify-around items-center bg-black bg-opacity-90 py-2">
        <NavItem icon={<FaThList />} text="Category" link="/category" showText={false} isActive={pathname === '/Category'} setIsHovering={setIsHovering} />
        <NavItem icon={<LuLayoutPanelLeft />} text="Updates" link="/updates" showText={false} isActive={pathname === '/update'} setIsHovering={setIsHovering} />
        <NavItem icon={<FaHome />} text="Home" link="/" showText={false} isActive={pathname === '/'} setIsHovering={setIsHovering} />
        <NavItem icon={<FaSearch />} text="Search" link="/search" showText={false} isActive={pathname === '/search'} setIsHovering={setIsHovering} />
        <NavItem icon={<FaUserCircle />} text="My Space" link="/myspace" showText={false} isActive={pathname === '/myspace'} setIsHovering={setIsHovering} />
      </div>
    </>
  );
}

const NavItem = ({ icon, text, link, showText, isActive, setIsHovering }) => {
  return (
    <div className="relative">
      <Link
        to={link}
        className={`nav-item cursor-pointer flex items-center px-4 group hover:text-white transition-transform duration-300 ease-in-out transform hover:scale-110 ${isActive ? 'text-white font-bold ' : 'text-white/50'}`}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        <div className="mb-5 text-lg">{icon}</div>
        <span
          className={`absolute left-full ml-2 whitespace-nowrap transition-all duration-500 ease-in-out ${showText ? 'opacity-100' : 'opacity-0'} ${showText ? 'delay-0' : 'delay-00'}`}
        >
          {text && <div className=" -mt-5 text-xl font-semibold">{text}</div>}
        </span>
      </Link>
    </div>
  );
}

export default Navbar;
