import { Routes, Route } from 'react-router-dom';
import Home from './Components/Pages/Home';
import MySpace from './Components/Pages/MySpace';
import MainList from './Components/MainBannerPage/MainList';
import Search from './Components/Pages/Search';
// import MoviesData from "./Components/GetData/MoviesData";
import Category from './Components/Pages/Category';
import HomeListCategory from './Components/Pages/HomeListCategory';
import Layout from './Components/Pages/Layout';
import Watch from './Components/Pages/Watch';
// import CategoryLayout from "./Components/CategoryPage/CategoryLayout";
// import Categories from "./Components/CategoryPage/Category";
// import Indian from './Components/CategoryPage/Indian'
// import NonInadian from "./Components/CategoryPage/NonInadian";
import UpdatePage from './Components/Pages/UpdatePage';
import Request from './Components/Request/Request';
import Login from './Components/Pages/login';

import AccDeletion from './Components/policy/AccDeletion';
import RaatRangDeletion from './Components/policy/RaatRangDeletion';
import Cinematixdeletion from './Components/policy/Cinematixdeletion';
import Cinematixprivacy from './Components/policy/Cinematixprivacy';
import RaatRangPolicy from './Components/policy/RaatRangPolicy';
import Privacy from './Components/policy/Privacy';
import Promotion from './Components/Pages/promotion';
import AdultPromotion from './Components/Pages/adultpromo';


function App() {
  
  return (
    <>
      <div>
        <Routes>
            <Route path='promotion' element={<Promotion />} />
            <Route path='adult' element={<AdultPromotion />} />
            <Route path='pikchar_data_deletion' element={<AccDeletion />} />
            <Route path='raatrang_data_deletion' element={<RaatRangDeletion />} />
            <Route path='pikchar_privacy' element={<Privacy />} />
            <Route path='raatrang_privacy' element={<RaatRangPolicy />} />
            <Route
              path='cinematix_privacy_policy'
              element={<Cinematixprivacy />}
            />
            <Route
              path='cinematix_data_delete'
              element={<Cinematixdeletion />}
            />
          <Route path='/' element={<Layout />}>
            <Route index element={<Home />} />
            <Route path='/myspace' element={<MySpace />} />
            <Route path='/main-list/:id' element={<MainList />} />
            <Route path='/search' element={<Search />} />
            {/* <Route path="/movies-data" element={<MoviesData />} /> */}
            <Route path='/category' element={<Category />} />
            <Route path='/updates' element={<UpdatePage />} />
            <Route path='/category/:category' element={<HomeListCategory />} />
            <Route path='/request' element={<Request />} />
            {/* <Route path="/caterory_layout" element={<CategoryLayout />} > */}
            {/* <Route path="indian_content" element={<Indian />} /> */}
            {/* <Route path="non_indian_content" element={<NonInadian />} /> */}
          </Route>
          {/* </Route> */}
          <Route path='/watch/:id' element={<Watch />} />
          {/* <Route path="/request" element={<Request />} /> */}
          <Route
            path='/watch/:id/season/:seasonIndex/episode/:episodeIndex'
            element={<Watch />}
          />
        </Routes>
      </div>
    </>
  );
}

export default App;
