import React from 'react';
import {useNavigate} from 'react-router-dom';

const statusMessages = {
  403: {
    title: 'You are not Logged In',
    message: 'You need to log in to access this feature.',
  },
  200: {
    title: 'Request Submitted!',
    message: 'Your request has been submitted successfully! Please wait for 48 hours for it to be uploaded.',
  },
  404: {
    title: 'Already logged in another browser!',
    message: 'You are already logged in with this account on another browser. Please log out from the other browser before logging in here.',
  },
  402: {
    title: 'Log Out Restriction',
    message: 'You can log out 10 days after your last login.',
  },
  401: {
    title: 'Premium Not Active',
    message: 'Your premium subscription is not active. please purchase subscription from our Pikchar app available on Playstore',
  },
  422: {
    title: 'Request Pending',
    message: 'You have already submitted a request. Please wait for it to be uploaded before making a new request.',
  },
  414: {
    title: 'Request Limit Reached',
    message: 'You have reached the monthly request limit.',
  },
  429: {
    title: 'Too Many Requests',
    message: 'Too many requests! Please try again in 15 minutes.',
  },
  303: {
    title: 'Movie watch limit reached!',
    message: 'You have reached your daily limit for movies. You can watch another movie tomorrow, but you are still able to watch a webseries now.',
  },
  304: {
    title: 'Webseries watch limit reached!',
    message: 'You have reached your daily limit for web series. You can watch another series tomorrow, but you are still able to watch a movie now.',
  }, 
  222: {
    title: 'Download Started!',
    message: 'Your download has successfully started! In the meantime, you can install SM Player or VLC Player to watch the downloaded movie.',
  }, 
  111: {
    title: 'Download Comming soon...',
    message: 'Download feature is coming soon... till then you can watch this online by clicking on Watch Now!',
  },
};


const Modal = ({ statusCode, onClose }) => {

  const navigate = useNavigate();
  // const location = useLocation();
  console.log('Received statusCode:', statusCode);
  const { title, message } = statusMessages[statusCode] || {
    title: 'Unknown Error',
    message: 'An unknown error has occurred. please retry!',
  };

  const handleClose = () => {
    if (statusCode === 403) {
      navigate('/myspace');
    }else if (statusCode === 303 || statusCode === 304 || statusCode === 401){
      navigate(-1);
    }
    else if(!statusMessages[statusCode]){
      navigate(-1);
    } 
    else {
      onClose();
    }
  };

  return (
    <div className='min-w-screen h-screen animated fadeIn faster fixed left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover px-10'>
      <div className='absolute bg-black opacity-70 inset-0 z-0'></div>
      <div className='w-full max-w-lg p-5 relative mx-auto my-auto rounded-xl shadow-lg bg-[#1d1d1d] text-white'>
        <div className='text-center p-5 flex flex-col justify-center gap-10'>
          <h1 className='text-2xl'>{title}</h1>
          <p className='text-sm px-8'>{message}</p>
        </div>
        <div className='p-3 mt-2 text-center space-x-4 md:block'>
          <button
            className='mb-2 md:mb-0 bg-white px-5 py-2 text-sm shadow-sm tracking-wider border text-black font-bold rounded-full hover:shadow-lg hover:bg-gray-100'
            onClick={handleClose}>
            OK
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
