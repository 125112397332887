import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function Promotion() {

  // const navigate = useNavigate();

  useEffect(() => {
    const downloadLink = document.createElement('a');
    downloadLink.href = "https://play.google.com/store/apps/details?id=com.gyanesh.mobiletalkies&hl=en_IN";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }, []);

  return (
    <div className="bg-gray-900 text-white min-h-screen flex flex-col items-center justify-center">
      <div className="text-center p-6 max-w-3xl">
        <h1 className="text-4xl md:text-5xl font-bold mb-4">
          Discover the Ratings of Latest Trending Movies!
        </h1>
        <p className="text-lg md:text-xl mb-6">
          Download our app to stay updated with all the hottest movie recommendations and ratings, handpicked just for you.
        </p>
        <a href="https://play.google.com/store/apps/details?id=com.gyanesh.mobiletalkies&hl=en_IN" className=" ">        <button className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full transition duration-300">
          Download Now
        </button></a>
      </div>

      <div className="mt-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        <img
          src="https://wp.missmalini.com/wp-content/uploads/2017/11/movie.jpg"
          alt="Movie Theater"
          className="w-full h-64 object-cover rounded-lg shadow-lg transform hover:scale-105 transition duration-300"
        />
        <img
          src="https://news24online.com/wp-content/uploads/2023/10/Films.jpg"
          alt="Movie Reel"
          className="w-full h-64 object-cover rounded-lg shadow-lg transform hover:scale-105 transition duration-300"
        />
        <img
          src="https://images.filmibeat.com/img/2023/09/oscarsmain1-1695273838.jpg"
          alt="Cinema Screen"
          className="w-full h-64 object-cover rounded-lg shadow-lg transform hover:scale-105 transition duration-300"
        />
      </div>

      <div className="text-center mt-10">
        <p className="text-lg md:text-xl">
          Don't miss out on any trending movie! Download the app now and dive into the world of cinema.
        </p>
      </div>
    </div>
  );
}

export default Promotion;
