import React from 'react';

function Privacy() {
  return (
    <div className='max-w-2xl mx-auto p-4'>
      <h2 className='text-3xl font-bold mb-4 mt-4'>Privacy Policy</h2>

      <section className='mb-8'>
        <h3 className='text-xl font-bold mb-2'>1. Introduction</h3>
        <p>
          Welcome to Pikchar. This Privacy Policy explains how we collect, use,
          disclose, and safeguard your information when you use our Android
          application, Pikchar ("App"). By downloading, installing, and using
          the App, you agree to the collection and use of information in
          accordance with this policy.
        </p>
      </section>

      <section className='mb-8'>
        <h3 className='text-xl font-bold mb-2'>2. Information We Collect</h3>
        <p>
          We collect the following personal information from you when you create
          an account and use the App:
        </p>

        <ul className='list-disc ml-6 mb-8'>
          <li>Email Address</li>
          <li>First Name</li>
          <li>Last Name</li>
          <li>User ID</li>
        </ul>
      </section>

      <section className='mb-8'>
        <h3 className='text-xl font-bold mb-2'>
          3. How We Use Your Information
        </h3>
        <p>The information we collect is used for the following purposes:</p>

        <ul className='list-disc ml-6 mb-8'>
          <li>
            <strong>Account Creation and Management:</strong> We use your email
            address, user ID, first name, and last name to create and manage
            your account. This includes authenticating your identity, processing
            transactions, and maintaining your account.
          </li>
          <li>
            <strong>App Functionality:</strong> Your personal information
            enables us to provide essential features of the App, such as
            personalized recommendations, user profile management, and content
            access.
          </li>
          <li>
            <strong>Communication:</strong> We may use your email address to
            send you important updates, notifications, and promotional materials
            related to Cinematix. You can opt-out of receiving promotional
            emails at any time.
          </li>
        </ul>
      </section>

      <section className='mb-8'>
        <h3 className='text-xl font-bold mb-2'>
          4. Information Sharing and Disclosure
        </h3>
        <p>
          We do not sell, trade, or otherwise transfer your personal information
          to third parties except in the following circumstances:
        </p>

        <ul className='list-disc ml-6 mb-8'>
          <li>
            <strong>Service Providers:</strong> We may share your information
            with third-party service providers who assist us in operating the
            App, conducting our business, or servicing you, as long as those
            parties agree to keep this information confidential.
          </li>
          <li>
            <strong>Legal Requirements:</strong> We may disclose your
            information if required to do so by law or in response to valid
            requests by public authorities (e.g., a court or government agency).
          </li>
        </ul>
      </section>

      <section className='mb-8'>
        <h3 className='text-xl font-bold mb-2'>
          5. Security of Your Information
        </h3>
        <p>
          We implement a variety of security measures to maintain the safety of
          your personal information. However, please remember that no method of
          transmission over the Internet or method of electronic storage is 100%
          secure, and we cannot guarantee absolute security.
        </p>
      </section>

      <section className='mb-8'>
        <h3 className='text-xl font-bold mb-2'>6. Your Privacy Rights</h3>
        <p>
          Depending on your jurisdiction, you may have the following rights
          regarding your personal information:
        </p>

        <ul className='list-disc ml-6 mb-8'>
          <li>
            <strong>Access and Correction:</strong> You can request access to
            your personal information and ask us to correct any inaccuracies.
          </li>
          <li>
            <strong>Deletion:</strong> You can request the deletion of your
            personal information, subject to certain exceptions.
          </li>
          <li>
            <strong>Opt-Out:</strong> You can opt-out of receiving promotional
            emails from us by following the unsubscribe instructions in those
            emails.
          </li>
        </ul>
      </section>

      <section className='mb-8'>
        <h3 className='text-xl font-bold mb-2'>
          7. Changes to This Privacy Policy
        </h3>
        <p>
          We may update our Privacy Policy from time to time. We will notify you
          of any changes by posting the new Privacy Policy on this page and
          updating the effective date. You are advised to review this Privacy
          Policy periodically for any changes.
        </p>
      </section>

      <section className='mb-8'>
        <h3 className='text-xl font-bold mb-2'>8. Contact Us</h3>
        <p>
          If you have any questions or concerns about this Privacy Policy or our
          data practices, please contact us at:
        </p>
        <p>
          <strong>Email:</strong> pikcharxdynamix@gmail.com
        </p>
      </section>
    </div>
  );
}

export default Privacy;
