import React, { useEffect, useState } from 'react';
import title from '../MoviesList/titltes.json'
import name from '../MoviesList/names.json';
import poster from '../MoviesList/posters.json';
import series from '../MoviesList/series.json';
import genres from '../MoviesList/genres.json';
import languages from '../MoviesList/lang.json';
import { FaPlay } from "react-icons/fa";
import { Link, useNavigate } from 'react-router-dom';
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const decodedLanguage = (code) => {
  let decoded = "";
  if (code.toLowerCase().includes("h")) decoded += "Hindi • ";
  if (code.toLowerCase().includes("e")) decoded += "English • ";
  if (code.toLowerCase().includes("p")) decoded += "Punjabi • ";
  if (code.toLowerCase().includes("g")) decoded += "Gujarati • ";
  if (code.toLowerCase().includes("m")) decoded += "Marathi • ";
  if (code.toLowerCase().includes("b")) decoded += "Bengali • ";
  if (code.toLowerCase().includes("k")) decoded += "Kannada • ";
  if (code.toLowerCase().includes("t")) decoded += "Tamil • ";
  if (code.toLowerCase().includes("u")) decoded += "Telugu • ";
  if (code.toLowerCase().includes("o")) decoded += "Odia • ";
  if (code.toLowerCase().includes("l")) decoded += "Malayalam • ";
  return decoded.slice(0, -3);
};

const decodeGenre = (code) => {
  let decoded = "";
  if (code.toLowerCase().includes("a")) decoded += "Action • ";
  if (code.toLowerCase().includes("h")) decoded += "Horror • ";
  if (code.toLowerCase().includes("d")) decoded += "Adventure • ";
  if (code.toLowerCase().includes("r")) decoded += "Romance • ";
  if (code.toLowerCase().includes("c")) decoded += "Comedy • ";
  if (code.toLowerCase().includes("m")) decoded += "Drama • ";
  if (code.toLowerCase().includes("n")) decoded += "Animation • ";
  if (code.toLowerCase().includes("i")) decoded += "Crime • ";
  if (code.toLowerCase().includes("y")) decoded += "Mystery • ";
  if (code.toLowerCase().includes("f")) decoded += "Fantasy • ";
  if (code.toLowerCase().includes("o")) decoded += "Sci-Fi • ";
  if (code.toLowerCase().includes("t")) decoded += "Thriller • ";
  if (code.toLowerCase().includes("s")) decoded += "History • ";
  if (code.toLowerCase().includes("l")) decoded += "Family • ";
  if (code.toLowerCase().includes("u")) decoded += "Documentary • ";
  if (code.toLowerCase().includes("b")) decoded += "Biography • ";
  if (code.toLowerCase().includes("e")) decoded += "Music • ";
  if (code.toLowerCase().includes("p")) decoded += "Sports • ";
  if (code.toLowerCase().includes("w")) decoded += "Western • ";
  if (code.toLowerCase().includes("g")) decoded += "War • ";
  if (code.toLowerCase().includes("j")) decoded += "News • ";
  if (code.toLowerCase().includes("x")) decoded += "Adult • ";
  return decoded.slice(0, -3);
};

export const mergeDatasets = (title, name, poster, series, genres, languages) => {
  const merged = {};
  Object.keys(title).forEach(categoryId => {
    const categoryInfo = title[categoryId];
    const categoryTitle = categoryInfo.title;
    const length = parseInt(categoryInfo.length, 10);
    const items = [];
    for (let i = 1; i <= length; i++) {
      const itemId = categoryInfo[i.toString()];
      const itemName = name[itemId] || "";
      const itemPoster = poster[itemId] || (series[itemId] ? series[itemId].mainPoster : "") || "";
      const itemGenre = decodeGenre(genres[itemId] || "");
      const itemLanguage = decodedLanguage(languages[itemId] || (series[itemId] ? series[itemId].lang : "") || "");
      const itemSeries = series[itemId] ? series[itemId].seasons : {};
      
      const seasons = [];
      if (Object.keys(itemSeries).length > 0) {
        if (itemSeries.seasons_names) {
          for (const seasonKey in itemSeries.seasons_names) {
            const seasonName = itemSeries.seasons_names[seasonKey];
            const season = itemSeries[seasonName];
            if (season && season.episodes_url) {
              seasons.push({
                name: seasonName,
                episodes: season.episodes_url
              });
            }
          }
        } else {
          for (const seasonName in itemSeries) {
            if (Object.prototype.hasOwnProperty.call(itemSeries, seasonName) && seasonName !== 'seasons_names') {
              const season = itemSeries[seasonName];
              if (season && season.episodes_url) {
                seasons.push({
                  name: seasonName,
                  episodes: season.episodes_url
                });
              }
            }
          }
        }
      }

      items.push({ id: itemId, name: itemName, poster: itemPoster, genres: itemGenre, languages: itemLanguage, seasons });
    }
    merged[categoryTitle] = items;
  });
  return merged;
};

const HomeList = () => {
  const [mergedData, setMergedData] = useState({});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  // useEffect(() => {
  //   setMergedData(mergeDatasets(title, name, poster, series, genres, languages));
  // }, []);
  useEffect(() => {
    setTimeout(() => {
      setMergedData(mergeDatasets(title, name, poster, series, genres, languages));
      setLoading(false);
    }, 2000); // Simulating a delay for loading
  }, []);
  
  const slideLeft = (category) => {
    const slider = document.getElementById(`slider-${category}`);
    if (slider) {
      slider.scrollBy({
        left: -500,
        behavior: 'smooth'
      });
    }
  };

  const slideRight = (category) => {
    const slider = document.getElementById(`slider-${category}`);
    if (slider) {
      slider.scrollBy({
        left: +500,
        behavior: 'smooth'
      });
    }
  };

  const viewMore = (category) => {
    navigate(`/category/${category}`);
  };
  
  return (
    // <div className='md:ml-28 sm:ml-10 ml-0 relative md:-mt-20 lg:-mt-56 mt-0  z-10'>
    //   {Object.keys(mergedData).map(category => (
    //     <div key={category} className='overflow-x-auto'>
    //       <div className='flex items-center justify-between max-sm:pl-4'>
    //         <p className='font-bold sm:text-2xl  text-[13px] ml-4 '>{category}</p>
    //         <div className=' sm:px-4  flex flex-row w-40 justify-end items-center font-bold opacity-55 hover:opacity-100  transition-transform ease-linear'>
    //         <button onClick={() => viewMore(category)} className=' text-xs sm:text-base font-bold   '>View More</button>
    //          <MdKeyboardArrowRight className=' text-2xl font-bold' />
    //         </div> 
    //       </div>

    //       <div className='relative flex items-center justify-start group gap-5 mb-2 max-md:overflow-y-hidden'>
    //         <div onClick={() => slideLeft(category)} className="absolute  px-7 hover:bg-gradient-to-r from-[#0C0404] to-transparent md:h-[290px] h-[220px]  flex items-center justify-start  cursor-pointer z-30  ">
    //           <MdKeyboardArrowLeft className="text-white text-3xl font-bold"  />
    //         </div>
            
    //         <div id={`slider-${category}`} className=" flex overflow-x-auto gap-4 md:h-[290px] h-[220px] items-center -mt-4 px-5 ">
    //           {mergedData[category].map(item => {
    //             const [movieTitle, year] = item.name.split(/ \((\d{4})\)/);
    //             return (
    //               <div key={item.id} className='relative'>
    //                 <div className='relative md:w-[180px] md:h-[230px] sm:w-[150px] sm:h-[200px] w-[130px] h-[180px] overflow-x-auto rounded-lg transition-transform ease-out duration-500 delay-300 sm:hover:scale-[1.25] hover:z-20 '>
    //                 <Link to={`/main-list/${item.id}`} className=' '>
    //                   <img src={item.poster} alt={item.name} className='md:w-[180px] md:h-[230px] sm:w-[150px] sm:h-[200px] w-[130px] h-[180px] rounded-lg transition-transform duration-500 transform sm:hover:scale-[1.20]' />
    //                   </Link>
    //                   <footer className=' max-sm:hidden absolute inset-0 bg-black bg-opacity-60 opacity-0 hover:opacity-100 flex flex-col justify-end text-white p-4 transition-opacity duration-300'>
    //                   <span className=' flex-1 text-green-400 font-bold text-[10px]'>{item.languages}</span>
    //                     <Link to={`/main-list/${item.id}`} className='mb-2 bg-white text-black text-xs w-full h-8 font-semibold rounded-md duration-200 ease-linear transform hover:scale-[1.020] flex items-center justify-center gap-3'>
    //                       <FaPlay className='text-[10px]' /> Watch Now
    //                     </Link>
    //                     <p className="uppercase text-xs font-bold ">{movieTitle} {year}</p>
    //                     <div className='text-xs font-semibold flex-col flex flex-2'>
    //                       {/* <span>{year}</span>  */}
    //                       <span className=" text-[#fffcc2] text-[10px]">{item.genres}</span> 
    //                       {/* <span>{item.languages}</span> */}
    //                     </div>
    //                   </footer>
    //                 </div>
    //               </div>
    //             );
    //           })}
    //         </div>
    //         <div onClick={() => slideRight(category)} className="absolute right-0 flex items-center hover:bg-gradient-to-r from-transparent to-[#0C0404] h-[280px] cursor-pointer z-30 ">
    //           <MdKeyboardArrowRight className="text-white text-3xl font-bold mr-8 "  />
    //         </div>

    //       </div>
    //     </div>
    //   ))}
    // </div>
    <div className='md:ml-28 ml-0 relative md:-mt-20 lg:-mt-56 mt-0 z-10'>
      {loading ? (
        Array(3).fill().map((_, index) => (
          <div key={index} className='overflow-x-auto'>
            <div className='flex items-center justify-between max-sm:pl-4'>
              {/* <Skeleton  baseColor=' grey' className='ml-4   h-[26px]  w-[400px] md:w-[100px] ' /> */}
              <div className='md:w-[400px] w-[100px] h-[26px] bg-gray-300 rounded-md animate-pulse md:ml-5'></div>
            </div>
            <div className='relative flex items-center justify-start group gap-5 mb-2 max-md:overflow-y-hidden'>
              <div className="flex overflow-x-auto gap-4 md:h-[290px] h-[220px] items-center -mt-4 px-5">
                {Array(15).fill().map((_, i) => (
                  <div key={i} className='relative'>
                    <div className='relative md:w-[180px] md:h-[230px] sm:w-[150px] sm:h-[200px] w-[130px] h-[180px]'>
                      <Skeleton height="100%" width="100%" baseColor=' grey' />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))
      ) : (
        Object.keys(mergedData).map(category => (
          <div key={category} className='overflow-x-auto'>
            <div className='flex items-center justify-between max-sm:pl-4 '>
              <p className='font-bold sm:text-2xl text-[13px] md:ml-4 ml-1'>{category}</p>
              <div className='sm:px-4 flex flex-row w-40 justify-end items-center font-bold opacity-55 hover:opacity-100 transition-transform ease-linear'>
                <button onClick={() => viewMore(category)} className='text-xs sm:text-base font-bold'>View More</button>
                <MdKeyboardArrowRight className='text-2xl font-bold' />
              </div>
            </div>

            <div className='relative flex items-center justify-start group gap-5 mb-2 max-md:overflow-y-hidden'>
              <div onClick={() => slideLeft(category)} className="absolute px-7 hover:bg-gradient-to-r from-[#0C0404] to-transparent md:h-[290px] h-[220px] flex items-center justify-start cursor-pointer z-30">
                <MdKeyboardArrowLeft className="text-white text-3xl font-bold" />
              </div>

              <div id={`slider-${category}`} className="flex overflow-x-auto gap-4 md:h-[290px] h-[190px] items-center -mt-4 px-5">
                {mergedData[category].map(item => {
                  const [movieTitle, year] = item.name.split(/ \((\d{4})\)/);
                  return (
                    <div key={item.id} className='relative'>
                      <div className='relative md:w-[180px] md:h-[230px] sm:w-[150px] sm:h-[200px] w-[105px] h-[150px] overflow-x-auto rounded-lg transition-transform ease-out duration-500 delay-300 sm:hover:scale-[1.25] hover:z-20'>
                        <Link to={`/main-list/${item.id}`} className=''>
                        <span className='absolute font-bold top-3 left-3 z-10 text-green-400 text-[8px] md:hidden'>{item.languages}</span>
                          <img src={item.poster} alt={item.name} className='md:w-[180px] md:h-[230px] sm:w-[150px] sm:h-[200px] w-[105px] h-[150px] rounded-lg transition-transform duration-500 transform sm:hover:scale-[1.20]' />
                        </Link>
                        <footer className='max-sm:hidden absolute inset-0 bg-black bg-opacity-60 opacity-0 hover:opacity-100 flex flex-col justify-end text-white p-4 transition-opacity duration-300'>
                          <span className='flex-1 text-green-400 font-bold text-[10px]'>{item.languages}</span>
                          <Link to={`/main-list/${item.id}`} className='mb-2 bg-white text-black text-xs w-full h-8 font-semibold rounded-md duration-200 ease-linear transform hover:scale-[1.020] flex items-center justify-center gap-3'>
                            <FaPlay className='text-[10px]' /> Watch Now
                          </Link>
                          <p className="uppercase text-xs font-bold">{movieTitle} {year}</p>
                          <div className='text-xs font-semibold flex-col flex flex-2'>
                            <span className="text-[#fffcc2] text-[10px]">{item.genres}</span>
                          </div>
                        </footer>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div onClick={() => slideRight(category)} className="absolute right-0 flex items-center hover:bg-gradient-to-r from-transparent to-[#0C0404] h-[280px] cursor-pointer z-30">
                <MdKeyboardArrowRight className="text-white text-3xl font-bold mr-8" />
              </div>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default HomeList;
