import React from 'react';
import todayAdds from '../MoviesList/today adds.json';
import names from '../MoviesList/names.json';
import posters from '../MoviesList/posters.json';
import series from '../MoviesList/series.json';
import edits from '../MoviesList/edit.json';
import { Link } from 'react-router-dom';
import { FaPlay } from 'react-icons/fa';

const UpdatePage = () => {
  const name = (id) => {
    return names[id] || id;
  };

  const poster = (id) => {
    for (const key in series) {
      if (series[key].name === name(id)) {
        return series[key].mainPoster;
      }
    }
    return posters[id] || '';
  };

  const [headingKey, headingValue] = Object.entries(todayAdds)[0];
  const entries = Object.entries(todayAdds).slice(1);
  const editEntries = Object.entries(edits);

  return (
    <div className='md:ml-28 max-md:m-2 lg:mt-10 mt-5'>
      <div className='md:text-xl text-lg text-center font-semibold lg:mb-14 mb-5 flex justify-center items-center flex-col md:gap-4 gap-1'>
        <p>Latest content added</p>
        <p id={headingKey} className='md:text-2xl text-xl font-bold'>{headingValue}</p>
      </div>
      <div className='md:mt-10 mt-8  '>
         {/* web seriser  */}
        <h2 className='text-xl font-semibold mb-4'>New Episodes</h2>
        <div className=' md:flex md:flex-wrap md:justify-start md:items-center gap-5  grid sm:grid-cols-4 grid-cols-3  max-[320px]:grid-cols-2'>
          {editEntries.map(([key, value]) => (
            <div key={key} className='mb-4'>
              <div className='relative md:w-[180px] md:h-[230px] sm:w-[150px] sm:h-[200px] w-[105px] h-[150px] overflow-x-auto rounded-lg transition-transform ease-out duration-500 delay-300 sm:hover:scale-[1.25] hover:z-20'>
                <Link to={`/main-list/${key}`} className=''>
                  <span className='absolute font-bold md:top-3 top-1 md:left-3 left-1 z-10 text-white md:text-xl text-sm bg-black px-2 rounded-lg bg-opacity-50'>{value}</span>
                  <img
                    src={poster(key)}
                    alt={value}
                    className='md:w-[180px] md:h-[230px] sm:w-[150px] sm:h-[200px] w-[105px] h-[150px] rounded-lg transition-transform duration-500 transform sm:hover:scale-[1.20]'
                  />
                </Link>
                <footer className='max-sm:hidden absolute inset-0 bg-black bg-opacity-60 opacity-0 hover:opacity-100 flex flex-col justify-end text-white p-4 transition-opacity duration-300'>
                  <Link to={`/main-list/${key}`} className='mb-2 bg-white text-black text-xs w-full h-8 font-semibold rounded-md duration-200 ease-linear transform hover:scale-[1.020] flex items-center justify-center gap-3'>
                    <FaPlay className='text-[10px]' /> Watch Now
                  </Link>
                  <p className='text-xs font-semibold flex-col flex flex-2'>{name(key)}</p>
                </footer>
              </div>
            </div>
          ))}
        </div>
        {/* movies  */}
        <h2 className='text-xl font-semibold mb-4'>New Movies & Series</h2>
      <div className='md:flex md:flex-wrap md:justify-start md:items-center md:gap-6  grid sm:grid-cols-4 grid-cols-3 max-md:gap-5 max-[320px]:grid-cols-2 '>
        {entries.map(([key, value]) => (
          <div key={key} className=' mb-4'>
            <div className='relative md:w-[180px] md:h-[230px] sm:w-[150px] sm:h-[200px] w-[105px] h-[150px] overflow-x-auto rounded-lg transition-transform ease-out duration-500 delay-300 sm:hover:scale-[1.25] hover:z-20'>
              <Link to={`/main-list/${key}`} className=''>
                <span className='absolute font-bold md:top-3 top-1 md:left-3 left-1 z-10 text-white md:text-xl text-sm bg-black px-2 rounded-lg bg-opacity-50'>{value}</span>
                <img
                  src={poster(key)}
                  alt={value}
                  className='md:w-[180px] md:h-[230px] sm:w-[150px] sm:h-[200px] w-[105px] h-[150px] rounded-lg transition-transform duration-500 transform sm:hover:scale-[1.20]'
                />
              </Link>
              <footer className='max-sm:hidden absolute inset-0 bg-black bg-opacity-60 opacity-0 hover:opacity-100 flex flex-col justify-end text-white p-4 transition-opacity duration-300'>
                <Link to={`/main-list/${key}`} className='mb-2 bg-white text-black text-xs w-full h-8 font-semibold rounded-md duration-200 ease-linear transform hover:scale-[1.020] flex items-center justify-center gap-3'>
                  <FaPlay className='text-[10px]' /> Watch Now
                </Link>
                <p className='text-xs font-semibold flex-col flex flex-2'>{name(key)}</p>
              </footer>
            </div>
          </div>
        ))}
      </div>
      {/* <div className='mt-10'>
        <h2 className='text-xl font-semibold mb-4'>Edited Movies/Series</h2>
        <div className='grid sm:grid-cols-5 grid-cols-2 sm:gap-4 gap-2 mb-10'>
          {editEntries.map(([key, value]) => (
            <div key={key} className='mb-4'>
              <div className='relative md:w-[220px] md:h-[280px] sm:w-[200px] sm:h-[260px] w-[150px] h-[200px] overflow-x-auto rounded-lg transition-transform ease-out duration-500 delay-300 sm:hover:scale-[1.25] hover:z-20'>
                <Link to={`/main-list/${key}`} className=''>
                  <span className='absolute font-bold top-3 left-3 z-40 text-green-600 text-xl'>{value}</span>
                  <img
                    src={poster(key)}
                    alt={value}
                    className='md:w-[220px] md:h-[280px] sm:w-[200px] sm:h-[260px] w-[150px] h-[200px] rounded-lg transition-transform duration-500 transform sm:hover:scale-[1.20]'
                  />
                </Link>
                <footer className='max-sm:hidden absolute inset-0 bg-black bg-opacity-60 opacity-0 hover:opacity-100 flex flex-col justify-end text-white p-4 transition-opacity duration-300'>
                  <Link to={`/main-list/${key}`} className='mb-2 bg-white text-black text-xs w-full h-9 font-semibold rounded-md duration-200 ease-linear transform hover:scale-[1.020] flex items-center justify-center gap-3'>
                    <FaPlay className='text-xs' /> Watch Now
                  </Link>
                  <p className='uppercase text-sm font-bold'>{name(key)}</p>
                </footer>
              </div>
            </div>
          ))}
        </div> */}
      </div>
    </div>
  );
};

export default UpdatePage;
